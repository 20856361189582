import React, { useEffect } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../components/Layout'

import Spacing from '../../components/partials/Spacing'
import HeaderSEO from '../../components/partials/HeaderSEO'

import { initializeVideos } from '../../helpers/initializeVideo'

const sql = () => {
  const data = useStaticQuery(graphql`
    query SqlSEO {
      allContentfulPages(
        filter: { pageReference: { pageIdentifier: { eq: "Product_SQL" } } }
      ) {
        edges {
          node {
            title {
              title
            }
            keywords {
              keywords
            }
            description {
              description
            }
            oGimage {
              fluid(quality: 100) {
                src
              }
            }
          }
        }
      }
    }
  `)

  const pageSEO = data.allContentfulPages.edges[0].node

  useEffect(() => {
    try {
      window.scrollTo(0, 0)
      initializeVideos()
    } catch (err) {
      console.log(err)
    }
  }, [])

  const handleFormCTA = (e) => {
    e.preventDefault()
    let email = document.querySelector<HTMLInputElement>('#email').value
    let validatedEmail = validateEmail(email)
    if (validatedEmail) {
      localStorage.setItem('userEmail', email)
      window.location.href = '/start'
    }
  }

  const validateEmail = (email) => {
    // if (email.value.includes("gmail.com") || email.value.includes("yahoo.com"))
    if (email.length === 0) {
      document
        .querySelector<HTMLFormElement>('#emailForm')
        .classList.add('border-danger')
      document.querySelector<HTMLInputElement>('#email').placeholder =
        'Please provide a valid email'
      return false
    } else {
      document
        .querySelector<HTMLFormElement>('#emailForm')
        .classList.remove('border-danger')
      document.querySelector<HTMLInputElement>('#email').placeholder = 'Email'
      // document.getElementById('heroForm').classList.add('border-success')
      return true
    }
  }
  return (
    <Layout newsletter={false}>
      <HeaderSEO pageSEO={pageSEO} />
      {/* <img className="home-fixed-top" src={hero_lens} alt="Hero Lenses" /> */}
      <section className="overflow-hidden container-1 mx-auto">
        <div className="d-flex flex-wrap hero-margin tablet-margin-top mobile-margin-top align-items-start">
          <div className="col-lg-4 col-xl-5 col-12 mobile-padding-left">
            <div className="mt-5 pt-4">
              <p className="h4-like mb-1">Product / SQL</p>
              <h1>Drive your streaming data with SQL</h1>
              <div className="pr-5">
                <h2 className="hero-description-dark pr-3 pt-4 roboto">
                  Know and navigate what’s inside your real-time apps - without
                  knowing Apache Kafka
                </h2>
              </div>
              {/* <div className="mt-4">
                  <a className="primary-text" href="#">
                    CTATEXT <i className="red-pointer-right"></i>
                  </a>
                </div> */}
            </div>
          </div>
          <div className="col-lg-8 col-xl-7 pt-0 col-12 px-0 mobile-margin-top tablet-margin-top">
            <StaticImage
              className="img-fluid w-100"
              style={{ marginTop: '-30px' }}
              src="../../resources/images/product/sql/hero_SQL.png"
              placeholder="tracedSVG"
              alt="sql hero"
            />
          </div>
        </div>
      </section>

      <Spacing />
      <div className="container-1">
        <div className="text-center">
          <p className="roboto hero-description-dark">
            Discovering what data is generated by your different real-time apps
            and teams can take months.
            <br /> That’s before even processing it.
            <br />
            Lenses lets anyone explore and process data in one DataOps
            environment.
          </p>
        </div>
      </div>

      <Spacing />
      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap align-items-center justify-content-center">
            <div className="col-12 col-md-5">
              <h2>Real-time data catalog</h2>
              <p>
                First, use the catalog to explore metadata across your real-time
                data platform & apps including Kafka and Elasticsearch.
              </p>
              <ul className="blue-checked-list">
                <li>Automatic data discovery</li>
                <li>Google-search metadata</li>
                <li>Real time topology and lineage</li>
              </ul>
              <div className="mt-4">
                <a
                  className="primary-text"
                  href="/blog/2020/07/data-dump-real-time-data-catalog-apache-kafka/"
                >
                  Read Blog<i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-7 mobile-margin-top-half">
              <StaticImage
                src="../../resources/images/product/sql/real_time_data_catalog.svg"
                placeholder="tracedSVG"
                alt="Real time data catalog"
              />
            </div>
          </div>
        </div>
      </section>
      <Spacing />
      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap align-items-center justify-content-center">
            <div className="col-12 col-md-7 mobile-margin-top mb-5">
              <StaticImage
                src="../../resources/images/product/sql/explore_payload_sql.svg"
                placeholder="tracedSVG"
                alt="Explore payload SQL"
              />
            </div>
            <div className="col-12 col-md-5">
              <h2>Explore payload with SQL</h2>
              <p>
                Next enrich, transform and filter streaming data using SQL
                semantics. No coding required.
              </p>
              <ul className="blue-checked-list">
                <li>Explore payload across any serialization</li>
                <li>Create rules for data masking</li>
                <li>Restrict data access with security namespaces</li>
              </ul>
              <div className="mt-4">
                <a
                  className="primary-text"
                  href="/blog/2020/07/why-sql-your-key-to-querying-kafka/"
                >
                  Read Blog<i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Spacing />
      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap align-items-center justify-content-center">
            <div className="col-12 col-md-5 mobile-margin-top">
              <h2>Real-time data processing with SQL</h2>
              <p>
                Then filter, aggregate, transform and reshape streaming data.
              </p>
              <ul className="blue-checked-list">
                <li>
                  Deploy over your existing Kubernetes or Connect infrastructure
                </li>
                <li>Build in monitoring & alerting</li>
                <li>Integrate data into 3rd party stores with connectors</li>
              </ul>
              <div className="mt-4">
                <a
                  className="primary-text"
                  href="/blog/2020/07/Why-new-Streaming-SQL-opens-up-data-platform/"
                >
                  Read Blog<i className="red-pointer-right"></i>
                </a>
              </div>
            </div>
            <div className="col-12 col-md-7 mobile-margin-top-half">
              <StaticImage
                src="../../resources/images/product/sql/real_time_data_processing.svg"
                placeholder="tracedSVG"
                alt="Real-time data processing with SQL"
              />
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1">
          <div className="text-center">
            <h2> Why use a Lenses workspace? </h2>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <StaticImage
                  className="w-75"
                  src="../../resources/images/product/sql/enableAppTeams_SQL.svg"
                  placeholder="tracedSVG"
                  alt="Kafka Guide"
                />
                <div className="pb-4 px-3">
                  <h3 className="text-center fw-400 mx-auto lh-29">
                    Enable app teams
                  </h3>
                  <p className="text-center pt-1">
                    Open up your platform to anyone that speaks data
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <StaticImage
                  className="w-75"
                  placeholder="tracedSVG"
                  src="../../resources/images/product/sql/deployAnyData_SQL.svg"
                  alt="Security Call"
                />
                <div className="pb-4 px-3">
                  <h3 className="text-center fw-400 lh-29">
                    Deploy on your infrastructure
                  </h3>
                  <p className="text-center pt-1">
                    Explore data then scale data processing on Kubernetes
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="border-top-0 rounded-1">
                <StaticImage
                  className="w-75"
                  src="../../resources/images/product/sql/reachProduction_SQL.svg"
                  placeholder="tracedSVG"
                  alt="Reach Production Safely"
                />
                <div className="pb-4 px-3">
                  <h3 className="text-center fw-400 lh-29">
                    Reach production safely
                  </h3>
                  <p className="text-center pt-1">
                    Meet complex compliance with security & auditing
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-5 col-10 pb-0 mobile-padding-left tablet-padding-left">
              <h4 className="mb-1">How to</h4>
              <h2>Navigate data with SQL</h2>
              <p>
                Our Snapshot SQL helps you find those needles in a haystack of
                real-time data. We walk you through how it works.
              </p>
              <p>
                <b>Explore </b>data
              </p>
              <p>
                <b>Debug</b> microservices
              </p>
              <p>
                <b>React</b> to live data
              </p>
            </div>
            <div className="col-md-1 col-12"></div>
            <div className="col-md-6 col-12">
              <div className="cloud-aws-msk-video">
                <iframe
                  src=""
                  title="Navigating & Querying Apache Kafka with SQL from Lenses.io on Vimeo"
                  data-src="//player.vimeo.com/video/443057175"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* <!-- Testimonial --> */}

      <Spacing />
      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap justify-content-center align-items-center">
            <div className="col-6 col-sm-7  testimonial-open-source py-3 ">
              <StaticImage
                className="testimonial-open-source-company-image"
                src="../../resources/images/clients/avanza/avanza.png"
                placeholder="tracedSVG"
                alt="Avanza"
              />
              <p className="testimonial-text">
                Our teams use Lenses.io as a portal to provide insights into how
                the data is represented, but also as a mechanism for feedback on
                the schema itself
              </p>
              <a className="primary-text" href="/customers/avanza/">
                Read More <i className="red-pointer-right" />
              </a>
              <StaticImage
                className="arrow-right-image"
                src="../../resources/images/homepage/testimonials/triangle.svg"
                placeholder="tracedSVG"
                alt="triangle"
              />
            </div>

            <div className="offset-2 offset-sm-1 col-3 flex-column flex-sm-row align-items-center d-flex mobile-padding-reset">
              <StaticImage
                imgClassName="quote-image"
                className="w-50"
                src="../../resources/images/clients/avanza/anders_eriksson.png"
                placeholder="tracedSVG"
                alt="Anders Eriksson"
              />
              <div className="ml-3 d-flex w-100 flex-column mobile-margin-reset">
                <div>
                  <p className="light-blue fw-400 mb-0"> Anders Eriksson</p>
                </div>
                <div>Data Engineer</div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Spacing />
      <section>
        <div className="container-1">
          <div className="d-flex flex-wrap align-items-center">
            <div className="col-md-5 col-10 pb-0 mobile-padding-left tablet-padding-left">
              <p className="h4-like mb-1">How to</p>
              <h2>Build apps with SQL</h2>

              <p>
                <b>Transform</b> data
              </p>
              <p>
                <b>Run</b> on any Kubernetes
              </p>
              <p>
                <b>Extend</b> with UDFs/UDAFs
              </p>
            </div>
            <div className="col-md-1 col-12"></div>
            <div className="col-md-6 col-12">
              <div className="cloud-aws-msk-video">
                <iframe
                  src=""
                  title="Build real-time apps with Streaming SQL from Lenses.io on Vimeo"
                  data-src="//player.vimeo.com/video/448874532"
                  style={{
                    position: 'absolute',
                    top: '0',
                    left: '0',
                    width: '100%',
                    height: '100%',
                  }}
                  frameBorder="0"
                  allow="autoplay; fullscreen"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Spacing />
      <section>
        <div className="container-1">
          <p className="h4-like mb-1 pl-3 ">Featured</p>
          <h2 className="pl-3">SQL guides</h2>
          <div className="d-flex flex-wrap ">
            <div className="col-md col-sm-6 mobile-padding-left tablet-padding-left col-12 mt-1">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100"
                  src="../../resources/images/product/sql/data_catalog.png"
                  placeholder="tracedSVG"
                  alt="Data Catalog"
                />
                <div className="py-4 px-3">
                  <h3 className="h4-like mb-1">Blog</h3>
                  <p className="cera-pro">
                    Data dump to data catalog for <b> Apache Kafka</b>
                  </p>
                  <a
                    className="primary-text home-link-box"
                    href="/blog/2020/07/data-dump-real-time-data-catalog-apache-kafka/"
                  >
                    Read blog <i className="red-pointer-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100"
                  src="../../resources/images/product/sql/streaming_sql.png"
                  placeholder="tracedSVG"
                  alt="Streaming SQL"
                />
                <div className="py-4 px-3">
                  <h3 className="h4-like mb-1">Blog</h3>
                  <p className="cera-pro">
                    Why our new Streaming SQL opens up your data platform
                  </p>
                  <a
                    className="primary-text home-link-box"
                    href="/blog/2020/07/Why-new-Streaming-SQL-opens-up-data-platform/"
                  >
                    Read blog <i className="red-pointer-right" />
                  </a>
                </div>
              </div>
            </div>
            <div className="col-md col-sm-6 col-12 mt-1">
              <div className="home-three-items-box border-top-0 rounded-1">
                <StaticImage
                  className="w-100"
                  src="../../resources/images/product/sql/sql_querying_kafka.png"
                  placeholder="tracedSVG"
                  alt="SQL Querying Kafka"
                />
                <div className="py-4 px-3">
                  <h3 className="h4-like mb-1">Blog</h3>
                  <p className="cera-pro">
                    Why SQL is your key to querying Kafka
                  </p>
                  <a
                    className="primary-text home-link-box"
                    href="/blog/2020/07/why-sql-your-key-to-querying-kafka/"
                  >
                    Read blog <i className="red-pointer-right" />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Spacing />

      <section className="px-5 mobile-padding-reset">
        <div className="container-1 bg-dark p-5 rounded-1 mobile-round-0 ">
          <div className="d-flex flex-wrap flex-column align-content-center align-items-center">
            <p className="cera-pro text-white paragraph-title f-24 fw-400">
              Try out SQL on real-time data
            </p>

            <form
              id="emailForm"
              className="d-flex align-items-center justify-content-center form-sql-page bg-white mt-1 mb-3"
              action="#"
            >
              <input
                type="email"
                name="email"
                autoComplete="email"
                className="border-0 f-13 ml-2 w-100 py-1"
                id="email"
                placeholder="Email"
                required
              />
              <button
                className="bg-light-blue text-white form-sql-page-button f-14 mr-1 pt-1 px-3 w-50 fw-700 text-center cursor-pointer border-0 "
                onClick={(e) => handleFormCTA(e)}
                type="submit"
              >
                Sign me up
              </button>
            </form>
          </div>
        </div>
      </section>
      <Spacing />
    </Layout>
  )
}
export default sql
